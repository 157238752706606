<template>
  <div>
    <div class="field">
      <label class="centered-items">
        <span class="icon is-small">
          <i class="fal fa-eye" />
        </span>
        <span>Properties</span>
      </label>
    </div>
    <DropdownMultiselect
      v-bind="{ options: columns, isCustom }"
      @toggle="toggleColumn"
    />
  </div>
</template>

<script>
export default {
  name: 'DataTableColumnToggle',
  components: {
    DropdownMultiselect: () =>
      import('modules/shared/dropdown/DropdownMultiselect')
  },
  props: {
    columns: {
      type: Array,
      required: true
    }
  },
  computed: {
    isCustom() {
      return this.columns.some(({ display }) => !display)
    }
  },
  methods: {
    toggleColumn($event) {
      return this.$emit('toggleColumn', $event)
    },
  }
}
</script>